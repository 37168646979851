<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Expense
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Edit </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-form method="post" @submit="updateExpenses">
			<b-card>
				<b-row>
					<b-col md="6">
						<b-form-group label="Production Type" label-for="Production Type">
							<div class="form-label-group">
								<b-form-select
									v-model="FormData.production_type_id"
									disabled
									:options="productionTypes"
									text-field="name"
									value-field="id"
									clearable:false
									:reduce="(e) => e.id"
								/>
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label="Select Flock" label-for="Select Flock">
							<div class="form-label-group">
								<b-form-select
									v-model="FormData.flock_id"
									disabled
									:options="flockList"
									text-field="flock_id"
									value-field="flock_id"
								/>
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label=" Date " label-for=" Date ">
							<b-form-datepicker
								name="purchase_date"
								v-model="FormData.created_at"
								menu-class="w-100"
								calendar-width="100%"
								class="mb-1"
							/>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label-for="Labour Salary" label="Labour Salary">
							<div class="form-label-group">
								<b-form-input
									v-model="FormData.labour_sal"
									placeholder="Labour Salary"
									type="text"
									name="labour_sal"
								/>
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label-for="Fuel" label="Fuel">
							<div class="form-label-group">
								<b-form-input v-model="FormData.fuel" placeholder="Fuel" type="text" name="fuel" />
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label-for="Rent" label="Rent">
							<div class="form-label-group">
								<b-form-input v-model="FormData.rent" placeholder="Rent" type="text" name="rent" />
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label-for="Litter Cost" label="Litter Cost">
							<div class="form-label-group">
								<b-form-input
									v-model="FormData.litter_cost"
									placeholder="Litter Cost"
									type="text"
									name="litter_cost"
								/>
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label-for="POL" label="POL">
							<div class="form-label-group">
								<b-form-input v-model="FormData.pol" placeholder="POL" type="text" name="pol" />
							</div>
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label-for="Other" label="Other">
							<div class="form-label-group">
								<b-form-input v-model="FormData.other" placeholder="Other" type="text" name="other" />
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<!-- submit and reset -->
				<b-col>
					<b-button type="submit" variant="primary" value="Submit" class="mr-1"> Submit </b-button>
				</b-col>
			</b-card>
		</b-form>
	</div>
</template>

<script>
import * as Vue from "vue";
import axiosIns from "@/libs/axios";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VBToggle } from "bootstrap-vue";
import { useRoute } from "vue-router";

export default {
	components: {},
	data() {
		return {
			selected: null,
			FormData: {
				created_at: null,
				// production_type_id: null,
				rent: null,
				fuel: null,
				flock_id: null,
				medical: null,
				labour_sal: null,
				pol: null,
				litter_cost: null,
				other: null,
			},
			expense: {},
			productionTypes: [],
			flockList: [],
		};
	},
	created: function () {
		this.getExpenseById();
		this.getProductionType();
		this.getSelectedFlockList();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getProductionType() {
			axiosIns
				.get(`web/production-types`)
				.then((response) => {
					this.productionTypes = response.data.production_type.data;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getSelectedFlockList() {
			const farm_id = this.$route.params.farmId;
			axiosIns
				.get(`web/farm/${farm_id}/flock-list`)
				.then((response) => {
					this.flockList = response.data.flock_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getExpenseById() {
			const id = this.$route.params.expenseId;
			axiosIns
				.get(`web/expenses/${id}`)
				.then((response) => {
					// this.hatch_id = response.data.expense.hatch_id;
					this.FormData.production_type_id = response.data.expense.production_type_id;
					this.FormData.flock_id = response.data.expense.flock_id;
					this.FormData.rent = response.data.expense.rent;
					this.FormData.created_at = response.data.expense.created_at;
					this.FormData.fuel = response.data.expense.fuel;
					this.FormData.labour_sal = response.data.expense.labour_sal;
					this.FormData.pol = response.data.expense.pol;
					// this.FormData.water = response.data.expense.water;
					// this.FormData.medical = response.data.expense.medical;
					this.FormData.litter_cost = response.data.expense.litter_cost;
					this.FormData.other = response.data.expense.other;
					this.id = id;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		updateExpenses(e) {
			e.preventDefault();
			var data = new FormData();
			data.append("rent", this.FormData.rent);
			// data.append("production_type_id", this.FormData.production_type_id);
			data.append("fuel", this.FormData.fuel);
			data.append("litter_cost", this.FormData.litter_cost);
			data.append("labour_sal", this.FormData.labour_sal);
			// data.append("medical", this.FormData.medical);
			data.append("pol", this.FormData.pol);
			data.append("other", this.FormData.other);
			data.append("_method", "PUT");
			const id = this.$route.params.expenseId;
			axiosIns
				.post(`web/expenses/${id}`, data)
				.then((response) => {
					response;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "warning",
							text: `Data Updated Successfully `,
						},
					});
					setTimeout(
						this.$router.push({
							name: `apps-expense-list`,
						}),
						3000
					);
				})

				.catch((error) => {
					// (error.response);
					this.$refs.setErrors(error.response.data.error);
				});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
